<template>
  <div class="ma-10">
    <div>前置準備轉址</div>
    <div>Page 3</div>
    <div>進行認證授權呼叫之服務端口</div>
    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {
  },
  props: {},
  data: function () {
    return {
    }
  },
  computed: {},
  watch: {
  },
  created: function () {
  },
  mounted: function () {
  },
  destroyed () {},
  methods: {
  }
}
</script>

<style>
</style>
